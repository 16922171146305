/**
 * List of errors that should be ignored by Sentry
 * This list was populated based on incidents started since April 1, 2024, to present
 * Please, add the error message to the list below with a comment explaining why it should be ignored
 */
export const ignoredErrors = [
  {
    error: "TypeError: can't access dead object",
    description: "Firefox addons error. We do not have control over this.",
  },
  {
    error:
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    description: "React internal error. Not useful for debugging.",
  },
  {
    error:
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    description: "React internal error. Not useful for debugging.",
  },
  {
    error: "NS_ERROR_FAILURE",
    description:
      "Common Firefox or Thunderbird error indicating a generic failure that does not fit into a specific category. Not useful for debugging.",
  },
  {
    error: "updateAndCleanExtractedContent",
    description: "Microsoft Edge IA services internal error.",
  },
  {
    error:
      /TypeError: Cannot read properties of undefined \(reading 'default'\)/,
    description:
      "Dynamic import error. Mostly caused by connection errors on the client side.",
  },
  {
    error:
      "NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https://events.launchdarkly.com",
    description:
      "LaunchDarkly network error. It can happen due to client or LaunchDarkly incident. Not actionable by us.",
  },
  {
    error: "TypeError: can't redefine non-configurable property 'solana'",
    description: "Solana wallet error. Looks like a user browser extensions.",
  },
  {
    error: "Viewport height is too small: 0",
    description: "Internal error from iframe-resizer-lib. Not actionable.",
  },
  {
    error: "Error: Login required",
    description: "Expired token Auth0 SDK error. Not actionable.",
  },
];
