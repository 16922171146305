import { createContext } from "react";
import { useAuthContext } from "context/AuthContext/useAuthContext";
import { AxiosController } from "context/FetchContext/AxiosController";
import { TRAINING_SERVER_AXIOS_CONFIG } from "context/FetchContext/constants";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const { authState, getAuth0Token, authLogout } = useAuthContext();
  const { access } = authState;

  const trainingServerAxios = AxiosController(
    TRAINING_SERVER_AXIOS_CONFIG,
    access,
    getAuth0Token,
    authLogout
  );

  return <Provider value={{ trainingServerAxios }}>{children}</Provider>;
};

export { FetchContext, FetchProvider };
