import { useFlags } from "launchdarkly-react-client-sdk";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useFetchContext } from "context/FetchContext/useFetchContext";
import { useErrorHandler } from "hooks/helpers/useErrorHandler";
import { getSupportData } from "utils/requests/authedAPI";

const fetchSupportData = (slug, trainingServerAxios) => {
  return trainingServerAxios(getSupportData(slug)).then(
    (response) => response.data
  );
};

const useSupportData = () => {
  const handleError = useErrorHandler();
  const { trainingServerAxios } = useFetchContext();
  const { supportHelpBarV2 } = useFlags();
  const location = useLocation();
  const slug = location?.pathname?.split("/")?.[2];
  const response = useQuery(
    ["support", slug],
    () => fetchSupportData(slug, trainingServerAxios),
    {
      onError: handleError,
      enabled: Boolean(supportHelpBarV2 && slug),
    }
  );
  return response;
};

export { useSupportData };
